import dashboard from './dashboard';
import utilities from './utilities';
import csvoperator from './csvoperator';
import csvagency from './csvagency';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, utilities, csvoperator, csvagency]
};

export default menuItems;
