// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';

// constant
const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const csvoperator = {
    id: 'Operator-csv',
    type: 'group',
    allowedRoles: ['operator'],
    children: [
        {
            id: 'master',
            title: 'Operator Details',
            type: 'collapse',
            children: [
                {
                    id: 'Operator-csv',
                    title: 'Operator csv',
                    type: 'item',
                    url: '/operator/operator-trip',
                    icon: icons.IconBrandChrome,
                    breadcrumbs: false
                },
                {
                    id: 'Operator-pending',
                    title: 'Operator Pending',
                    type: 'item',
                    url: '/operator/get-by-status/pending',
                    icon: icons.IconBrandChrome,
                    breadcrumbs: false
                },
                {
                    id: 'Operator-inreview',
                    title: 'Operator Inreview',
                    type: 'item',
                    url: '/operator/get-by-status/in-review',
                    icon: icons.IconBrandChrome,
                    breadcrumbs: false
                },
                {
                    id: 'Operator-complete',
                    title: 'Operator Complete',
                    type: 'item',
                    url: '/operator/get-by-status/complete',
                    icon: icons.IconBrandChrome,
                    breadcrumbs: false
                },
                {
                    id: 'Operator-freeze',
                    title: 'Operator Freezed',
                    type: 'item',
                    url: '/operator/get-by-status/freezed',
                    icon: icons.IconBrandChrome,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'trip-master',
            title: 'Trip Manager',
            type: 'collapse',
            children: [
                {
                    id: 'Trip-manager-csv',
                    title: 'Trip Manager',
                    type: 'item',
                    url: '/operator-trip-manager/view',
                    icon: icons.IconBrandChrome,
                    breadcrumbs: false
                },
                {
                    id: 'Add-trip-manager',
                    title: 'Add Trip Manager',
                    type: 'item',
                    url: '/add-operator-trip-manager',
                    icon: icons.IconBrandChrome,
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default csvoperator;
