import React from 'react';
import { Typography } from '@mui/material';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

const MenuList = ({ userRole }) => {
    // Filter menu items based on user role
    const filteredItems = menuItem.items.filter((item) => {
        if (item.allowedRoles && item.allowedRoles.includes(userRole)) {
            return true;
        }
        return false;
    });

    const navItems = filteredItems.map((item, index) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={index} item={item} />;
            default:
                return (
                    <Typography key={index} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
