// assets
import { IconPalette, IconShadow, IconTypography, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: '',
    type: 'group',
    allowedRoles: ['depo'],
    children: [
        {
            id: 'master',
            title: 'Depo',
            type: 'collapse',
            icon: icons.IconWindmill,
            children: [
                {
                    id: 'Operator',
                    title: 'Operator',
                    type: 'item',
                    url: '/depo/operator',
                    breadcrumbs: false
                },
                {
                    id: 'TransportAgency',
                    title: 'Transport Agency',
                    type: 'item',
                    url: '/depo/transport',
                    breadcrumbs: false
                },
                {
                    id: 'Schedule',
                    title: 'Schedule Master',
                    type: 'item',
                    url: '/depo/schedulemaster',
                    breadcrumbs: false
                },
                {
                    id: 'state',
                    title: 'state',
                    type: 'item',
                    url: '/master/state',
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default utilities;
