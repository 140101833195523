// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';

// constant
const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const csvagency = {
    id: 'Agency-csv',
    type: 'group',
    allowedRoles: ['agency'],
    children: [
        {
            id: 'Transport-csv',
            title: 'Transport csv',
            type: 'item',
            url: '/transport/transport-trip',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'Transport-pending',
            title: 'Transport Pending',
            type: 'item',
            url: '/transport/get-by-status/pending',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'Transport-inreview',
            title: 'Transport Inreview',
            type: 'item',
            url: '/transport/get-by-status/in-review',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'Transport-complete',
            title: 'Transport Complete',
            type: 'item',
            url: '/transport/get-by-status/complete',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        },
        {
            id: 'Transport-freeze',
            title: 'Transport Freezed',
            type: 'item',
            url: '/transport/get-by-status/freezed',
            icon: icons.IconBrandChrome,
            breadcrumbs: false
        }
    ]
};

export default csvagency;
